<template>
  <div>
    <div>
      <span>{{ label }}</span>
    </div>
    <div class="row">
      <q-select
        outlined
        bg-color="grey-3"
        color="default-pink"
        :disable="disable"
        dense
        emit-value
        map-options
        class="col-10"
        v-model="inputValue"
        v-on:update:model-value="$emit('sendValue', initalValue)"
        :options="listOptions"
        :rules="rules"
      />
    </div>
  </div>
</template>

<script>
//-----VUEJS
import { ref } from "vue";

export default {
  name: "InputSelect",
  props: {
    label: String,
    rules: Array,
    listOptions: Array,
    isPassword: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: true,
    },
    initalValue: Number,
  },
  emits: ["sendValue"],
  setup(props) {
    //----VARIABLES
    let inputValue = ref(props.initalValue);

    return {
      //----VARIABLES
      inputValue,
    };
  },
};
</script>

<style></style>
