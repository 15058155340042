<template>
  <div>
    <div>
      <span>{{ label }}</span>
    </div>
    <div class="row">
      <q-input
        dense
        outlined
        :type="isPassword ? 'password' : 'text'"
        class="col-10"
        color="default-pink"
        bg-color="grey-3"
        :disable="disable"
        v-model="inputValue"
        lazy-rules
        v-on:update:model-value="$emit('sendValue', initalValue)"
        :rules="rules"
      >
        <template v-slot:append>
          <slot name="append"></slot>
        </template>
      </q-input>
    </div>
  </div>
</template>

<script>
//-----VUEJS
import { ref } from "vue";

export default {
  name: "InputText",
  props: {
    label: String,
    rules: Array,
    isPassword: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: true,
    },
    initalValue: String,
  },
  emits: ["sendValue"],
  setup(props) {
    //----VARIABLES
    let inputValue = ref(props.initalValue);

    return {
      //----VARIABLES
      inputValue,
    };
  },
};
</script>

<style></style>
